.App {
  background: url('/images/salt-lake.jpg') center / cover;
}

.AppCaption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
}

.AppCaption span {
  background-color: #222;
  color: white;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
  line-height: 61px;
}

.Header {
  color: white;
}
